import _defineProperty from "@babel/runtime/helpers/defineProperty";
var API_DEV_URL = "https://guardiansapi-dev.azurewebsites.net";
var API_TEST_URL = "https://guardiansapi-test.azurewebsites.net";
var API_TEST_ASH_URL = "https://api-guardians-test.appservice.asheast2.teliacygate.cloud";
var API_STAGING_URL = "https://vhapi-staging.haldor.se";
var API_PROD_URL = "https://vhapi.haldor.se";
var LOGIN_DEV = "https://haldoridp-dev.azurewebsites.net";
var LOGIN_TEST = "https://haldoridp-test.azurewebsites.net";
var LOGIN_ASH_TEST = "https://app-identityserver-test.appservice.asheast2.teliacygate.cloud";
var LOGIN_STAGING = "https://auth-staging.haldor.se";
var LOGIN_PROD = "https://auth.haldor.se";
var AIKEY_ASH_TEST = "a068d6dc-4bb3-4d31-805e-f1e7be62423c";
var AIKEY_PROD = "d3d41ec0-18e8-44b4-95e9-3c73d9825683";
var IDP_CLIENT_TEST = "haldor.guardian.native.test";
var IDP_CLIENT_PROD = "haldor.guardian.native";
export var GET_CONFIG = function GET_CONFIG() {
  var config = {};
  if (window.location.host.indexOf("localhost") > -1 || window.location.host.indexOf("vh-dev.haldor.se") > -1) {
    config.API = API_TEST_ASH_URL;
    config.LOGIN = LOGIN_ASH_TEST;
    config.AIKEY = AIKEY_ASH_TEST;
    config.IDPCLIENTID = IDP_CLIENT_TEST;
  } else if (window.location.host == "vh-test.haldor.se" || window.location.host == "haldor2.eu.ngrok.io") {
    config.API = API_TEST_ASH_URL;
    config.LOGIN = LOGIN_ASH_TEST;
    config.AIKEY = AIKEY_ASH_TEST;
    config.IDPCLIENTID = IDP_CLIENT_TEST;
  } else if (window.location.host == "vh-staging.haldor.se") {
    config.API = API_STAGING_URL;
    config.LOGIN = LOGIN_STAGING;
    config.AIKEY = AIKEY_PROD;
    config.IDPCLIENTID = IDP_CLIENT_PROD;
  } else if (window.location.host == "vh.haldor.se" || window.location.host == "parent.haldoredu.com" || window.location.host == "parent.haldoredu.co.uk") {
    config.API = API_PROD_URL;
    config.LOGIN = LOGIN_PROD;
    config.AIKEY = AIKEY_PROD;
    config.IDPCLIENTID = IDP_CLIENT_PROD;
  }
  config.msal = _defineProperty({
    clientId: config.IDPCLIENTID,
    authority: config.LOGIN,
    postLogoutRedirectUri: window.location.origin + '/',
    redirectUri: "".concat(window.location.protocol, "//").concat(window.location.hostname).concat(window.location.port ? ":".concat(window.location.port) : '', "/loginRedirect"),
    navigateToLoginRequestUrl: false,
    knownAuthorities: [config.LOGIN],
    protocolMode: 'OIDC'
  }, "postLogoutRedirectUri", "".concat(window.location.protocol, "//").concat(window.location.hostname).concat(window.location.port ? ":".concat(window.location.port) : ''));
  return config;
};